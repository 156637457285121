// Global variables to store the current equity percentage and minimum equity
let currentEquityPercentage = 0;
let minimumEquity = 10; // Default value, will be updated if found in the form

// Function to update the minimum equity from the field with data-variable="minimum-equity-input"
function updateMinimumEquity(fields) {
    console.log('Updating minimum equity. Fields:', fields);
    if (!Array.isArray(fields)) {
        console.error('Fields is not an array:', fields);
        return;
    }
    const minEquityField = fields.find(field => field && field.variable === 'minimum-equity-input');
    if (minEquityField) {
        minimumEquity = parseFloat(minEquityField.value);
        console.log('Minimum equity updated:', minimumEquity + '%');
    } else {
        console.log('Minimum equity field not found. Using default:', minimumEquity + '%');
    }
}

// Listen for changes in the equity percentage and minimum equity
window.addEventListener('heyflow-change', (event) => {
    console.log('Heyflow change event:', event.detail);
    const { fields, fieldsSimple } = event.detail || {};
    
    if (fields) {
        updateMinimumEquity(fields);
    }

    const percentEquity = parseFloat(fieldsSimple && fieldsSimple['percent-equity-owned']);
    
    if (!isNaN(percentEquity)) {
        currentEquityPercentage = percentEquity;
        console.log('Current equity percentage:', currentEquityPercentage + '%');
    } else {
        console.log('Invalid equity percentage:', fieldsSimple && fieldsSimple['percent-equity-owned']);
    }
});

// Listen for button clicks
window.addEventListener('heyflow-button-click', (event) => {
    const { customEventName, customEventLabel } = event.detail || {};
    console.log('Button clicked:', customEventName, customEventLabel);
    
    // Check if this is the submit button (you may need to adjust this condition)
    if (customEventLabel === 'Submit' || customEventName === 'submit') {
        console.log('Submit button clicked. Current equity:', currentEquityPercentage + '%, Minimum equity:', minimumEquity + '%');
        if (currentEquityPercentage < minimumEquity) {
            // Only redirect if equity is below the minimum
            console.log('Redirecting to screen-redirect');
            window.location.hash = 'screen-redirect';
        } else {
            console.log('Equity is sufficient. No redirection needed.');
        }
    }
});

// Initialize minimum equity on page load
window.addEventListener('heyflow-init', (event) => {
    console.log('Heyflow init event:', event.detail);
    const { fields } = event.detail || {};
    if (fields) {
        updateMinimumEquity(fields);
    } else {
        console.log('Fields not available in init event. Using default minimum equity:', minimumEquity + '%');
    }
});

// Log form submission (for debugging purposes)
window.addEventListener('heyflow-submit', (event) => {
    console.log('Form submitted:', event.detail);
});

console.log('Heyflow script loaded and initialized');